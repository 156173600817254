import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Register from "./unAuthorised/Register";
import VerifyOtp from "./unAuthorised/VerifyOtp";
import SpinWheel from "./authorised/SpinWheel";
import PriceWon from "./authorised/PriceWon";
import TermsAndCondition from "./unAuthorised/TermsAndCondition";

export const PageRoutes = ({ isAuth, isWon }) => {
  return isWon ? (
    <PriceWon />
  ) : (
    <Router>{isAuth ? <AuthorisedRoutes /> : <UnauthorisedRoutes />}</Router>
  );
};

export const UnauthorisedRoutes = () => {
  return (
    <Routes>
      <Route path="/verifyotp/:mobile" element={<VerifyOtp />} />
      <Route path="/register" element={<Register />} />
      <Route path="/termsandconditions" element={<TermsAndCondition />} />
      <Route path="*" element={<Navigate to="/register" />} />{" "}
    </Routes>
  );
};

export const AuthorisedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SpinWheel />} />
      <Route path="*" element={<Navigate to="/" />} />{" "}
    </Routes>
  );
};
