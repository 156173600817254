import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import voucher from "../../assets/images/voucher.png";
import ContainedButton from "../../components/buttons/ContainedButton";
import Lottie from "lottie-react";
import Celebration from "../../assets/lotties/celebration1.json";
import congrats from "../../assets/lotties/congrats.json";
import { useDispatch } from "react-redux";
import { setIsAuth, setWon } from "../../store/authSlice";
import { removeAuthorizationToken } from "../../axios/instance";
import price from '../../assets/images/priceMoney.png'

const PriceWon = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    removeAuthorizationToken();
    sessionStorage.removeItem("access_token");
    dispatch(setIsAuth(false));
    dispatch(setWon(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex:1
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "50rem",
          alignSelf: "center",
          flex: 1,
        }}
      >
        <Header />
        <Box position={"relative"} flex={1}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 1,
              display: "flex",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Lottie
              animationData={Celebration}
              loop
              autoPlay
              style={{ flex: 1, height: "99%", transform: `scale(2)` }}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              px: 3,
              zIndex: 10,
              position: "relative",
              flex: 1,
            }}
          >
            {" "}
            <Lottie
              animationData={congrats}
              loop
              autoPlay
              style={{ flex: 1, height: "20rem", transform: `scale(1.4)` }}
            />
            <Paper
              sx={{
                p: 2.5,
                width: "100%",
                gap: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1rem",
                border: "6px solid #BC8346",

              }}
              elevation={3}
            >
              <Typography
                sx={{
                  fontSize: "1.73rem",
                  fontWeight: "700",
                  textAlign: "center",
                }}
                color="black"
              >
             Your answer was right you have won an Amazon Gift voucher worth
              </Typography>
              <Box
                sx={{
                  backgroundImage: `url(${price})`,
                  backgroundSize: "100% 100%",
                  width: "20rem",
                  alignSelf:'center',
                  height: "7.6rem",
                  position: "relative",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    textAlign: "center",
                    position: "absolute",
                    right: "2rem",
                    bottom: "1rem",
                  }}
                >
                  ₹100
                </Typography> */}
              </Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  width: "95%",
                  mb: 2,
                }}
                color="black"
              >
                * Redemption details and gift card code has been sent to your
                registered mobile number .
              </Typography>
              {/* <ContainedButton
                label={"Go Back To Home"}
                onClick={handleClick}
                fontFamily={"Nexa"}
                fontSize={"2rem"}
              /> */}
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PriceWon;

// const handleSpin = () => {
//   // Calculate the final rotation position

//   const finalRotation = currentRotation + getRandomRotation();
//   console.log("at start", currentRotation, finalRotation);

//   // Start the animation
//   // wheel.style.transition = "transform 0s"; // Reset transition
//   // wheel.style.transform = `rotate(${finalRotation}deg)`;

//   // Apply transition to start spinning
//   wheel.style.transition = `transform ${rotationSpeed}s cubic-bezier(0.4, 0.0, 0.2, 1)`;
//   wheel.style.transform = `rotate(${finalRotation + 360 * 5}deg)`; // Add multiple spins for at least 5 seconds
//   // wheel.style.transform = `rotate(${finalRotation + 360 * 5}deg)`; // Add multiple spins for at least 5 seconds

//   // Update the current rotation for the next spin

//   // currentRotation = finalRotation;
//   setcurrentRotation(finalRotation)
//   console.log("at end", currentRotation, finalRotation);
//   // Set a timeout to stop the animation after at least 5 seconds
//   // setTimeout(() => {
//   // wheel.style.transition = "none";
//   // wheel.style.transform = `rotate(${finalRotation}deg)`;
//   // }, rotationSpeed * 1000);
// };