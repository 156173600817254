import { openAxiosInstance, securedAxiosInstance } from "./instance";

const unSecured_apis = {};

const secured_apis = {};

unSecured_apis.registerUser = (reqBody) => {
  return openAxiosInstance.post(`/user/register`, reqBody);
};

unSecured_apis.verifyOtp = (reqBody) => {
  return openAxiosInstance.post(`/auth/verifyOtp`, reqBody);
};

unSecured_apis.reSendOtp = (reqBody) => {
  return openAxiosInstance.post(`/auth/reSendOtp`, reqBody);
};

secured_apis.getUserDetails = () => {
  return securedAxiosInstance.get(`/user/get/profile`);
};

secured_apis.spinTheWheel = () => {
  return securedAxiosInstance.get(`ticket/spin`);
};
const apis = {
  ...unSecured_apis,
  ...secured_apis,
};

export default apis;
