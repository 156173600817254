import { Box, Typography } from "@mui/material";
import React, { useDebugValue, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import ContainedButton from "../../components/buttons/ContainedButton";
import * as yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import apis from "../../axios/apis";
import toast from "react-hot-toast";
import { setAuthorizationToken } from "../../axios/instance";
import { useDispatch } from "react-redux";
import { setIsAuth } from "../../store/authSlice";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

useEffect(() => {
  if ("OTPCredential" in window) {
    const ac = new AbortController();

    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        formik.setFieldValue('otp',otp.code)
        // formik.handleSubmit()
        ac.abort();
      })
      .catch((err) => {
        ac.abort();
        console.log(err);
      });
  }

}, [])


  const handlePaste = (event) => {
    const data = event.clipboardData.getData("text");
    console.log(data);
  };

  const handleSubmit = async (values) => {
    // dispatch(setIsAuth(true));
    // return;
    if (loading) return;
    setLoading(true);
    let showingToast = toast.loading("Loading...");
    try {
      let { data } = await apis.verifyOtp({
        mobile: params.mobile.toString(),
        otp: values.otp,
      });
      toast.success(data?.message, {
        id: showingToast,
      });
      setAuthorizationToken(data?.access_token);
      sessionStorage.setItem("access_token", data?.access_token);
      dispatch(setIsAuth(true));
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message || error.message, {
        id: showingToast,
      });
    }
    setLoading(false);
  };

  const reSendOtp = async () => {
    if (loading) return;
    setLoading(true);
    let showingToast = toast.loading("Loading...");
    try {
      let { data } = await apis.reSendOtp({ mobile: params.mobile });
      toast.success(data?.message, {
        id: showingToast,
      });
      formik.resetForm();
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message || error.message, {
        id: showingToast,
      });
    }
    setLoading(false);
  };

  const otpValidation = yup.object({
    otp: yup
      .string()
      .min(6, "Enter a valid 6 digit OTP")
      .max(6, "Enter a valid 6 digit OTP")
      .required("Enter a valid 6 digit OTP"),
  });

  const formik = useFormik({
    validationSchema: otpValidation,
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "", sm: "center" },
        px: { xs: 4 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: "6rem" },
          flex: 1,
          maxWidth: "50rem",
          width: "100%",
          gap: "2rem",
          // alignItems:{xs:"",sm:'center'},
        }}
      >
        <Box>
          <Typography component={"p"} variant="verifyOtp">
            OTP Verification
          </Typography>
          <Typography fontSize={"1.4rem"} py={".5rem"}>
            Enter the OTP sent to{" "}
            <Typography component="span" fontWeight={"700"} fontSize={"1.4rem"}>
              +91 {params.mobile}
            </Typography>
          </Typography>
        </Box>
        <Box>
          <OTPInput
            value={formik.values.otp}
            onChange={(value) => formik.setFieldValue("otp", value)}
            // onPaste={handlePaste}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} type="number" />}
            containerStyle={{ justifyContent: "space-between" }}
            inputStyle={{
              width: "4rem",
              outline: "none",
              border: "none",
              borderRadius: "1rem",
              backgroundColor: "#F3F3F3",
              height: "5rem",
              textAlign: "center",
              fontWeight: "800",
              fontFamily: "Nexa",
            }}
            skipDefaultStyles
          />
          <Typography
            sx={{
              color: "red",
              fontSize: { xs: "1.2rem" },
              mt: { xs: "0.2rem" },
            }}
          >
            {formik?.errors.otp && formik?.touched.otp && formik?.errors.otp}
          </Typography>
        </Box>
        <ContainedButton label={"Verify"} onClick={formik.handleSubmit}/>
        <Typography fontSize={"1.4rem"}>
          Didn’t you receive the OTP?
          <Typography
            component="span"
            fontWeight={"700"}
            fontSize={"1.4rem"}
            sx={{ cursor: "pointer" }}
            onClick={reSendOtp}
          >
            {" "}
            Resend OTP
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default VerifyOtp;
