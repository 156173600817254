import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#5E25B2",
    },
  },
  typography: {
    // Tell Material UI what the font-size on the html element is.
    htmlFontSize: 10,
    fontFamily: "Montserrat",
    allVariants: {
      color: "#5E25B2",
      fontFamily: "Montserrat",
      fontWeight: "500",
    },
    welcomeText: {
      color: "#5E25B2",
      fontFamily: "AccordAlt-ExtraBold",
      fontSize: "4.0rem",
      fontStyle: "normal",
      fontWeight: "800",
    },
    verifyOtp: {
      color: "#5E25B2",
      fontFamily: "Montserrat",
      fontSize: "2.9rem",
      fontStyle: "normal",
      fontWeight: "700",
    },
    textInput: {
      marginBottom: "0.4rem",
      fontWeight: "600",
      fontSize: "1.6rem",
      color: "#5E25B2",
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 640,
  //     lg: 1025,
  //     xl: 1536,
  //   },
  // },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
});

export default muiTheme;
