import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../components/customInputs/TextInput";
import SelectInput from "../../components/customInputs/SelectInput";
import ContainedButton from "../../components/buttons/ContainedButton";
import registerImage from "../../assets/images/registedBgDull.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import apis from "../../axios/apis";
import { useSelector } from "react-redux";
import Preloader from "../../components/Preloader";
import spinThewheeltext from "../../assets/images/spinthewheelText.svg";
import Tnc from "../../components/modals/Tnc";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tncOpen, setTncOpen] = useState(false);
  const { initialLoad } = useSelector((state) => state.auth);
  const userTypes = [
    {
      id: 1,
      label: "Contractor",
      value: "contractor",
    },
    {
      id: 2,
      label: "Painter",
      value: "painter",
    },
    {
      id: 3,
      label: "Customer",
      value: "customer",
    },
  ];

  const handleSubmit = async (values = formik.values) => {
    if (loading) return;
    setTncOpen(false);
    setLoading(true);

    let showingToast = toast.loading("Loading...");

    try {
      let { data } = await apis.registerUser({
        ...values,
        ["mobile"]: values.mobile.toString(),
      });
      console.log(data);
      toast.success(data?.message, {
        id: showingToast,
      });
      navigate(`/verifyotp/${values.mobile}`);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message, {
        id: showingToast,
      });
    }
    setLoading(false);
  };

  const registerValidation = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters")
      .max(30, "Name cannot be greater then 30 characters")
      .test(
        "no-blank-spaces",
        "Name must not contain blank spaces",
        (value) => {
          return value === undefined || value.trim() === value;
        }
      ),
    mobile: yup
      .string()
      .min(10, "Enter a valid 10 digit number")
      .max(10, "Enter a valid 10 digit number")
      .required("Mobile number is required"),
    type: yup.string().required("User type is required"),
  });

  const formik = useFormik({
    validationSchema: registerValidation,
    enableReinitialize: true,
    initialValues: {
      name: "",
      mobile: "",
      type: "",
    },
    onSubmit: () => {
      if(loading) return
      setTncOpen(true);
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: { xs: 4 },
        flexDirection: "column",
        background: `url(${registerImage}) no-repeat bottom left `,
        backgroundSize: { xs: "50vh", sm: "50vh", md: "60vh" },
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography variant="welcomeText">Welcome To</Typography>
        <Typography
          variant="welcomeText"
          sx={{ fontWeight: "800", fontFamily: "AccordAlt-ExtraBold" }}
        >
          Ultima Protek
        </Typography>
      </Box>
      <Box sx={{ height: "10rem", widht: "100%" }}>
        <img
          src={spinThewheeltext}
          alt="spin the wheel"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          maxWidth: "50rem",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            gap: "2rem",
          }}
        >
          <TextInput
            name={"name"}
            label="Name *"
            placeholder="Enter your name"
            formik={formik}
          />
          <TextInput
            name={"mobile"}
            label="Mobile Number *"
            placeholder="Enter your mobile number"
            formik={formik}
            type="number"
          />
          <SelectInput
            name={"type"}
            label="User Type *"
            options={userTypes}
            placeholder={"Select user type"}
            formik={formik}
          />
          <ContainedButton label="Get OTP" type="submit" disabled={loading} />
        </form>
      </Box>
      {initialLoad && <Preloader />}
      <Tnc
        open={tncOpen}
        handleClose={() => {
          handleSubmit();
        }}
      />
    </Box>
  );
};

export default Register;
