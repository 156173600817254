import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  isWon: false,
  initialLoad: true,
  chancesLeft: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setWon: (state, action) => {
      state.isWon = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setInitialLoad: (state, action) => {
      state.initialLoad = action.payload;
    },
    setChancesLeft: (state, action) => {
      state.chancesLeft = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsAuth, setMobile, setWon, setInitialLoad, setChancesLeft } =
  authSlice.actions;

export default authSlice.reducer;
