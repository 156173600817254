import { Modal, Box, Typography } from "@mui/material";
import React from "react";
import bgImage from "../../assets/images/bgImage.png";
import ContainedButton from "../buttons/ContainedButton";
import paint1 from "../../assets/images/paint1.png";
import paint2 from "../../assets/images/paint2.png";

const Rules = ({ open, handleClose, chancesLeft }) => {
  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      sx={{ display: "grid", placeItems: "center", px: 2 }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          // border: "6px solid #BC8346",
          background: `url(${bgImage})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          boxShadow: "0px 0px 33.7px 0px #FF8206 inset",
          borderRadius: "1rem",
          px: 3.5,
          py: 4,
          gap: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "2.6rem",
              fontWeight: "800",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            Ultima Protek
          </Typography>
          <Typography
            sx={{
              fontSize: "2.6rem",
              fontWeight: "800",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            spin and win game
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: "700",
              textTransform: "capitalize",
              textAlign: "center",
              color: "black",
            }}
          >
            What does ultima protek stand for ?
          </Typography>
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: "700",
              textTransform: "capitalize",
              textAlign: "center",
              color: "black",
            }}
          >
            you will get 3 chances to land on the right answer
          </Typography>
        </Box>
        <ContainedButton
          label={"Your Spin is Ready, Spin Now !"}
          fontFamily="Nexa"
          onClick={handleClose}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <img src={paint1} alt="ultima protect" />
          <img src={paint2} alt="ultima protect" />
        </Box>
      </Box>
    </Modal>
  );
};

export default Rules;
