import { Box, Paper } from "@mui/material";
import React from "react";
import apLogo from "../assets/images/ap-logo.svg";
const Header = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: { xs: 4 },
        pt: { xs: "4rem" },
        pb: { xs: "2.5rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: { xs: "0.4rem" },
          // px: { xs: 4 },
          width:'100%',
          maxWidth:'50rem',
          py: { xs: 1.5 },
          pb: { xs: 1 },
        }}
      >
        <img
          src={apLogo}
          alt="asian paints"
          style={{ width: "50%", height: "auto"}}
        />
      </Box>
    </Box>
  );
};

export default Header;
