import React, { useEffect, useLayoutEffect, useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import muiTheme from "./theme/theme";
import toast, { Toaster } from "react-hot-toast";
import { PageRoutes } from "./routes/routes";
import Header from "./components/Header";
import Preloader from "./components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import {
  setChancesLeft,
  setInitialLoad,
  setIsAuth,
  setWon,
} from "./store/authSlice";
import {
  removeAuthorizationToken,
  setAuthorizationToken,
} from "./axios/instance";
import apis from "./axios/apis";
const App = () => {
  const dispatch = useDispatch();
  const { isWon, isAuth } = useSelector((state) => state.auth);
  useLayoutEffect(() => {
    checkIfVerified();
    setTimeout(() => {
      dispatch(setInitialLoad(false));
    }, 5000);
  }, []);

  const checkIfVerified = async () => {
    let access_token = sessionStorage.getItem("access_token");
    if (!access_token) return;
    setAuthorizationToken(access_token);
    dispatch(setIsAuth(true))
    // try {
    //   let { data } = await apis.getUserDetails();
    //   if (!data?.data?.can_spin || data?.data?.priceWon ) {
    //     removeAuthorizationToken();
    //     sessionStorage.removeItem("access_token");
    //     dispatch(setIsAuth(false));
    //     dispatch(setWon(false));
    //     return
    //   }
    //   dispatch(setIsAuth(true));
    //   dispatch(setChancesLeft(3-data?.data?.spinCount ));
    // } catch (error) {
    //   dispatch(setIsAuth(false));
    //   dispatch(setWon(false));
    //   removeAuthorizationToken();
    //   console.log(error);
    // }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      {/* {initalLoad &&
      !isAuth &&
      (window.location.pathname === "/register" ||
        window.location.pathname === "/") ? (
        <Preloader />
      ) : (
        <></>
      )} */}
      {!isWon && <Header />}
      <PageRoutes isAuth={isAuth} isWon={isWon} />
      <Toaster
        // containerStyle={{
        //   top:40
        // }}
        toastOptions={{
          className: "",
          duration: 3000,
          style: {
            fontFamily: "Nexa",
            fontSize: "1.5rem",
            fontWeight: "500",
            color: "#FFF",
            backgroundColor: "#5E25B2",
          },
        }}
      />
    </ThemeProvider>
  );
};

export default App;
