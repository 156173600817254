import { Box, Button, Typography } from "@mui/material";
import React, {
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import topArrow from "../../assets/images/topArrow.svg";
import SpinAgain from "../../components/modals/SpinAgain";
import wheelImg from "../../assets/images/wheelSvg.svg";
import Rules from "../../components/modals/Rules";
import bgRays from "../../assets/images/bgLines.svg";
import lightsBanner from "../../assets/images/lightBanner.png";
import wheelImage from "../../assets/images/wheel.png";
import wheelPin from "../../assets/images/wheelPin.png";
import apis from "../../axios/apis";
import { useDispatch, useSelector } from "react-redux";
import { setChancesLeft, setIsAuth, setWon } from "../../store/authSlice";
import { removeAuthorizationToken } from "../../axios/instance";

const SpinWheel = () => {
  const [openModel, setOpenModel] = useState(false);
  const [ruleModal, setRuleModal] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);
  const { chancesLeft } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const wheelRef = useRef();
  const [currentRotation, setcurrentRotation] = useState(0);
  const [isUserWinner, setIsUserWinner] = useState(false);
  const [winningRotation, setWinningRotation] = useState(0);
  const [prevRotaion, setPrevRotaion] = useState(0);
  const [rotationCount, setRotationCount] = useState(0);
  const [rotationWhenReload, setRotationWhenReload] = useState(0);
  const randomArray = [
    {
      0: 310,
      1: 342,
      2: 353,
    },
    {
      0: 350,
      1: 342,
      2: 322,
    },
    {
      0: 305,
      1: 330,
      2: 306,
    },
    {
      0: 300,
      1: 315,
      2: 308,
    },
    {
      0: 298,
      1: 341,
      2: 203,
    },
    {
      0: 355,
      1: 342,
      2: 365,
    },
    {
      0: 365,
      1: 301,
      2: 333,
    },
    {
      0: 363,
      1: 308,
      2: 333,
    },
    {
      0: 342,
      1: 350,
      2: 342,
    },
    {
      0: 300,
      1: 345,
      2: 300,
    },
    {
      0: 300,
      1: 300,
      2: 432,
    },
  ];
  const [randomArrayOption, setRandomArrayOption] = useState(
    Math.floor(Math.random() * 10) + 1
  );
  let wheel = wheelRef.current;

  const checkIfVerified = async () => {
    try {
      let { data } = await apis.getUserDetails();
      if (!data?.data?.can_spin || data?.data?.priceWon) {
        removeAuthorizationToken();
        sessionStorage.removeItem("access_token");
        dispatch(setIsAuth(false));
        dispatch(setWon(false));
        return;
      }
      dispatch(setChancesLeft(3 - data?.data?.spinCount));
      setPrevRotaion(data?.data?.spinCount);
      setRotationWhenReload(data?.data?.spinCount);
      if (data?.data?.ticket?.is_attempt_winner) {
        setIsUserWinner(true);
        setWinningRotation(data?.data?.ticket?.attempt_number);
        if (data?.data?.spinCount !== 0) {
          setIsPageRefreshed(true);
        }
      }
    } catch (error) {
      dispatch(setIsAuth(false));
      dispatch(setWon(false));
      removeAuthorizationToken();
      sessionStorage.removeItem("access_token");
      console.log(error);
    }
  };

  const handleRefreshSpin = () => {
    let returnVal;
    if (winningRotation === 2 || rotationWhenReload===2) {
      returnVal = 360;
    } else {
      returnVal = 342;
    }

    return returnVal;
  };

  const handleSpin = () => {
    console.log('randomArrayOption',randomArrayOption);
    console.log('prevRotaion',prevRotaion);
    console.log('rotationWhenReload',rotationWhenReload);
    console.log('rotationCount',rotationCount);

    // Calculate the final rotation position
    let rotationSpeed = 10;
    let newRortationCount = prevRotaion + 1;
    setRotationCount(rotationCount + 1);
    setPrevRotaion(prevRotaion + 1);

    setIsSpinning(true);

    const finalRotation = currentRotation + getRandomRotation();

    wheel.style.transition = `transform ${rotationSpeed}s cubic-bezier(0.2,0.2, 0.1, 1)`;
    wheel.style.transform = `rotate(${finalRotation + 360 * 5}deg)`; // Add multiple spins for at least 5 seconds

    callSpinApi();
    setcurrentRotation(finalRotation);

    setTimeout(() => {
      wheel.style.transition = "none";
      wheel.style.transform = `rotate(${finalRotation}deg)`;
      if (isUserWinner && newRortationCount === winningRotation) {
        dispatch(setWon(true));
      } else {
        setOpenModel(true);
        setIsSpinning(false);
        dispatch(setChancesLeft(chancesLeft - 1));
      }
    }, rotationSpeed * 1000 + 1000);
  };

  const getRandomRotation = () => {
    let randomRotaion;
    if (isUserWinner && isPageRefreshed) {
      randomRotaion = handleRefreshSpin();
    } else if (isUserWinner && winningRotation === 1) {
      randomRotaion = 360;
    } else if (isUserWinner && winningRotation === 2) {
      randomRotaion = 342;
    } else if (isUserWinner && winningRotation === 3) {
      randomRotaion = 300;
    } else {
      randomRotaion = randomArray[randomArrayOption][rotationCount];
    }

    return randomRotaion * 10;
  };

  const callSpinApi = async () => {
    try {
      let { data } = await apis.spinTheWheel();
      return data?.data?.is_attempt_winner;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useLayoutEffect(() => {
    document.getElementById("root").style.overflow = "hidden";
    if (chancesLeft === 0) {
      checkIfVerified();
    }

    return () => {
      document.getElementById("root").style.overflow = "scroll";
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          maxWidth: "50rem",
          mt: 2.5,
          px: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "700",
            width: "100%",
            py: 4,
            px: 2.5,
            textTransform: "uppercase",
            background: `url(${lightsBanner})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            color: "white",
            textAlign: "center",
          }}
        >
          Spin The wheel To Get Amazon Gift Vouchers
        </Typography>
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "600",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Chances Left : {chancesLeft}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          bottom: "-27vh",
          height: "100vh",
          width: "100vh",
          // transform: "rotate(-41deg)",
          // transformOrigin: "50% 60%"
        }}
      >
        <img
          src={wheelImage}
          alt="wheel"
          style={{ width: "80%", height: "80%" }}
          // id="spin-wheel"
          ref={wheelRef}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "-27vh",
          height: "100vh",
          width: "100vh",
        }}
      >
        <Button
          sx={{
            color: "white",
            width: "11rem",
            zIndex: 2,
            aspectRatio: 1,
            borderRadius: "100%",
            fontWeight: "800",
            fontSize: "2.8rem",
            marginTop: "19.6vh",
          }}
          onClick={handleSpin}
          disabled={isSpinning}
        >
          SPIN
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "-27vh",
          height: "100vh",
          width: "100vh",
          // transform: "rotate(-40deg)",
          // transformOrigin: "50% 60%"
        }}
      >
        <img
          src={wheelPin}
          alt="pin"
          style={{
            width: "19vh",
            aspectRatio: 1,
            marginTop: "17.2vh",
            marginLeft: "1.6vh",
          }}
        />
      </Box>
      <SpinAgain
        open={openModel}
        handleClose={() => setOpenModel(false)}
        chancesLeft={chancesLeft}
      />
      <Box
        sx={{
          position: "fixed",
          height: "70vh",
          top: 0,
          zIndex: "-1",
          background: `url(${bgRays})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          width: "100%",
        }}
      ></Box>
      <Rules open={ruleModal} handleClose={() => setRuleModal(false)} />
    </Box>
  );
};

export default SpinWheel;
