import { Box, Typography } from "@mui/material";
import React from "react";

const TermsAndCondition = () => {
  const tnc = [
    {
      id: 1,
      rule: "Each participant is limited to a maximum of three spins throughout the entire duration of the game.",
    },
    {
      id: 2,
      rule: "A participant can win only once, and multiple wins by the same individual will be voided.",
    },
    {
      id: 3,
      rule: "Asian Paints entity reserves all the rights to terminate or modify the game at any point, with or without notice.",
    },
    {
      id: 4,
      rule: "If the wheel lands on 'Lamination,' the participant wins a gratification, which will be sent to the registered mobile number.",
    },
    {
      id: 5,
      rule: "Participants are responsible for redeeming prizes themselves. Amazon voucher validity extends to one year before redemption.",
    },
    {
      id: 6,
      rule: "Amazon Vouchers are non-transferable and cannot be converted into cash.",
    },
    {
      id: 7,
      rule: "The Spin Wheel Game will be active from the (date mentioned) in the promotional material.",
    },
    {
      id: 8,
      rule: "Any attempt to manipulate the game or engage in fraudulent activities will result in immediate disqualification.",
    },
    {
      id: 9,
      rule: "The primary language for the game is English.",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
        gap: "2rem",
        py:2
      }}
    >
      <Typography  fontWeight={"700"} fontSize={'2.6rem'} textAlign={'center'}>
        Terms And Conditions
      </Typography>
      <Box pl={2}>
        <ul style={{fontSize:'2rem',}}>
          {tnc.map((_item) => (
            <li>
              <Typography color={'black'} id={_item.id} pb={'1rem'} >{_item.rule}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
