import { Box, Typography } from "@mui/material";
import React from "react";
import { FormHelperText } from "@mui/material";

const TextInput = ({ label, formik, name, ...props }) => {
  return (
    <Box sx={{width:'100%'}}>
      <Typography variant="textInput" component={'p'}>
        {label}
      </Typography>
      <input
        name={name}
        className="input-textfield"
        value={formik?.values[name]}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        {...props}
        step="0"
      />
      <Typography
        sx={{ color: "red", fontSize: { xs: "1.2rem" }, mt: { xs: "0.2rem" } }}
      >
        {formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      </Typography>
    </Box>
  );
};

export default TextInput;
