import { Button } from "@mui/material";
import React from "react";

const ContainedButton = ({ label, onClick, type, disabled, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        fontWeight: "500",
        fontFamily: "Montserrat",
        width: "100%",
        height: "100%",
        borderRight: "1rem",
        py: "1rem",
        fontSize:'1.6rem',
        ...props,
      }}
      onClick={onClick}
      type={type || "text"}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ContainedButton;
