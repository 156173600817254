import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import ContainedButton from "../buttons/ContainedButton";
import sadFace from "../../assets/lotties/sadface.json";
import Lottie from "lottie-react";
import bgImage from "../../assets/images/bgImage.png";
import { removeAuthorizationToken } from "../../axios/instance";
import { useDispatch } from "react-redux";
import { setIsAuth, setWon } from "../../store/authSlice";

const SpinAgain = ({ open, handleClose, chancesLeft }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (chancesLeft !== 0) {
      handleClose();
      return;
    }
    removeAuthorizationToken();
    sessionStorage.removeItem("access_token");
    dispatch(setIsAuth(false));
    dispatch(setWon(false));
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      sx={{ display: "grid", placeItems: "center", px: 2 }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // border: "6px solid #BC8346",
          background: `url(${bgImage})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          boxShadow: "0px 0px 33.7px 0px #FF8206 inset",
          borderRadius: "1rem",
          width: "100%",
          maxWidth: "40rem",
          color: "black",
          gap: 2,
          p: 3,
        }}
      >
        <Box>
          <Lottie
            animationData={sadFace}
            loop
            autoPlay
            style={{ flex: 1, height: "20rem" }}
          />
          <Typography
            sx={{
              fontSize: chancesLeft > 0?"2rem":'1.7rem',
              fontWeight: "700",
              textAlign: "center",
              mt: 4,
            }}
            color={"black"}
          >
            Sorry :(
          </Typography>
          <Typography
            sx={{ fontSize: chancesLeft > 0?"2rem":'1.7rem', fontWeight: "700", textAlign: "center" }}
            color={"black"}
          >
            Luck Was Not By Your Side
          </Typography>
        </Box>
        {chancesLeft <= 0 && (
          <Typography
            sx={{ fontSize: "2.3rem", fontWeight: "700", textAlign: "center" }}
            color={"#5E25B2"}
          >
            Ultima Protek stand for Lamination guard paint
          </Typography>
        )}
        <Box>
          <Typography
            sx={{ fontSize: "1.5rem", fontWeight: "600", textAlign: "center" }}
            color="black"
          >
            {chancesLeft > 0
              ? `But Still You Have ${chancesLeft} Chance left`
              : "Better luck next time!"}
          </Typography>
        </Box>
        {chancesLeft > 0 && (
          <ContainedButton
            label={chancesLeft !== 0 ? "Spin Again" : "Go Back To Home"}
            onClick={handleClick}
            fontFamily={"Nexa"}
            fontSize={"1.8rem"}
          />
        )}
      </Box>
    </Modal>
  );
};

export default SpinAgain;
