import { Modal, Box, Typography } from "@mui/material";
import React from "react";
import ContainedButton from "../buttons/ContainedButton";

const Tnc = ({ open, handleClose }) => {
  const tnc = [
    {
      id: 1,
      rule: "Each participant is limited to a maximum of three spins throughout the entire duration of the game.",
    },
    {
      id: 2,
      rule: "A participant can win only once, and multiple wins by the same individual will be voided.",
    },
    {
      id: 3,
      rule: "Asian Paints entity reserves all the rights to terminate or modify the game at any point, with or without notice.",
    },
    {
      id: 4,
      rule: "If the wheel lands on 'Lamination,' the participant wins a gratification, which will be sent to the registered mobile number.",
    },
    {
      id: 5,
      rule: "Participants are responsible for redeeming prizes themselves. Amazon voucher validity extends to one year before redemption.",
    },
    {
      id: 6,
      rule: "Amazon Vouchers are non-transferable and cannot be converted into cash.",
    },
    {
      id: 7,
      rule: "The Spin Wheel Game will be active from the (date mentioned) in the promotional material.",
    },
    {
      id: 8,
      rule: "Any attempt to manipulate the game or engage in fraudulent activities will result in immediate disqualification.",
    },
    {
      id: 9,
      rule: "The primary language for the game is English.",
    },
  ];
  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      sx={{ display: "grid", placeItems: "center", px: 2 }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: "white",
          pt: 3,
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          width: "90vw",
          borderRadius: "1rem",
        }}
      >
        <Typography
          fontWeight={"700"}
          fontSize={"2rem"}
          textAlign={"center"}
          color={"black"}
          pb={2}
        >
          Terms And Conditions
        </Typography>
        <Box
          pl={2}
          height={"100%"}
          sx={{ overflowY: "scroll", marginX: 2 }}
          px={2}
        >
          <ul style={{ fontSize: "1.5rem", color: "#3C3C3C" }}>
            {tnc.map((_item) => (
              <li>
                <Typography
                  color={"#3C3C3C"}
                  id={_item.id}
                  pb={"1rem"}
                  fontSize={"1.5rem"}
                >
                  {_item.rule}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Box
          py={2.5}
          width={"100%"}
          sx={{
            alignSelf: "center",
            boxShadow: "0px -1px 26px 0px rgba(0, 0, 0, 0.13)",
            display: "grid",
            placeItems: "center",
          }}
        >
          <ContainedButton
            label={"Accept And Continue"}
            fontFamily="Nexa"
            width="80%"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default Tnc;
