import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const SelectInput = ({
  label,
  formik,
  name,
  placeholder,
  options,
  onSelect,
  selected,
  props,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="textInput" component={"p"}>
        {label}
      </Typography>
      <div className="styled-select">
        <select
          name={name}
          className="input-select"
          // value={formik?.values[name]}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          defaultValue={formik?.values[name]}
          style={{ color: formik?.values[name].length ? "black" : "#5e636e" }}
        >
          <option hidden value>
            {" "}
            {placeholder}
          </option>
          {options.map((item) => (
            <option key={item.id} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <Typography
          sx={{
            color: "red",
            fontSize: { xs: "1.2rem" },
            mt: { xs: "0.2rem" },
          }}
        >
          {formik?.errors[name] &&
            formik?.touched[name] &&
            formik?.errors[name]}
        </Typography>
      </div>
    </Box>
  );
};

export default SelectInput;
