import { Box, Modal } from "@mui/material";
import React from "react";
import preloaderImg from "../assets/images/preloaderSvg.svg";
import bgImage from "../assets/images/bgImage.png";
import apLogo from "../assets/images/ap-logo.svg";
import apexText from "../assets/images/apenxText.svg";
import spinTheWheelSvg from "../assets/images/spinTheWhellImg.svg";
import popup from "../assets/images/popup.png";
import Lottie from "lottie-react";
import loaderLine from "../assets/lotties/loadingBar.json";

const Preloader = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "grid",
        placeItems: "center",
        zIndex: "1",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={popup} alt="popup" />
        <Lottie
          animationData={loaderLine}
          loop={true}
          style={{ position: "absolute", bottom: "2.2%", width: "90%" }}
        />
      </Box>
      {/* <Box
        sx={{
          border: "6px solid #BC8346",
          background: `url(${bgImage})`,
          backgroundSize:'120% 120%',
          backgroundPosition:'center',
          boxShadow: "0px 0px 33.7px 0px #FF8206 inset",
          borderRadius:'1rem',
          px:4,
          pb:4
        }}
      >
        <Box
          sx={{
            width: "25rem",
            height: "6.0rem",
            backgroundColor: "#FFF",
            p: 1.5,
            borderBottomLeftRadius: "2.6rem",
            borderBottomRightRadius: "2.6rem",
          }}
        >
          <img
            src={apLogo}
            alt="ap logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            width: "25rem",
            height: "17rem",
            p: 1.5,
          }}
        >
          <img
            src={apexText}
            alt="ap logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            width: "25rem",
            height: "25rem",
          }}
        >
          <img
            src={spinTheWheelSvg}
            alt="ap logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Box> */}
    </Box>
  );
};

export default Preloader;
